<template>
    <div>
        <div style="width:800px;margin:0 auto">
            <el-steps :active="active" align-center>
                <el-step title="基础信息"></el-step>
                <el-step title="结算信息"></el-step>
                <el-step title="照片信息"></el-step>
            </el-steps>
        </div>
        <BaseInfo @change="getBaseInfo" :provinceCityArea="provinceList" v-show="active==0"></BaseInfo>
        <SettleInfo  @change="getSettleInfo" :merType="baseInfo.merType" :provinceCityArea="provinceList" v-show="active==1"></SettleInfo>
        <ImgInfo  @change="getimgInfo" v-show="active==2" :accountType="accountType" :merType="baseInfo.merType"></ImgInfo>
    </div>
</template>
<script>
import BaseInfo from '@/components/merchantUpdateBase/baseInfo'
import SettleInfo from '@/components/merchantUpdateBase/settleInfo'
import ImgInfo from '@/components/merchantUpdateBase/imgInfo'
import { MerchantApi } from '@/api'
import { mapState} from 'vuex'
export default {
    components:{
        BaseInfo,
        SettleInfo,
        ImgInfo
    },
    data(){
        return{
            active: 0,
            baseInfo: {},
            setterInfo:{},
            params:{},
            accountType:'1',
            merType: ''
        }
    },
    computed:{
        ...mapState('app', ['provinceCityArea']),
        provinceList(){
            return '' || this.provinceCityArea
        }
    },
    methods:{
        getBaseInfo(params,type){
            if(type=='next'){
                this.active ++
                this.baseInfo = params
            }
        },
        getSettleInfo(params,type){
            if(type=='next'){
                this.active ++
                this.setterInfo = params
                this.accountType = params.accountType
            } if(type=='prev'){
                this.active --
            }
        },
        async getimgInfo(params,type){
            if(type=='submit'){
                let baseInfo = filterObj(this.baseInfo,['address','areaCode','cityCode','contactName','merSubName','mobileNo','provinceCode','majorMcc','subMcc'])
                let bussineBase = filterObj(this.baseInfo,['busiLicenseCode','busiLicensePeriod','companyName','licenseImg','legalName'])
                let businessSetter = filterObj(this.setterInfo,['legalCardPeriod','legalIdCard'])
                let businessInfo = Object.assign(bussineBase,businessSetter)
                let settleBase = filterObj(this.setterInfo,['accountImg','accountCity','accountName','accountNo','accountPermitImg','legalIdCardFaceImg','legalIdCardNationalEmblemImg','accountProvince','accountType','bankName','branchName','bankCode','branchNo','settleIdCard','settleIdCardPeriod','settleIdCardFaceImg','settleIdCardNationalEmblemImg','unLegalAuthorizeImg'])
                let imgInfo = filterObj(params,[,'accountPermitImg','cashierImg','doorImg','holdIdCardImg','premisesImg','settleHoldIdCardImg','legalIdCardFaceImg','legalIdCardNationalEmblemImg'])
                let settleInfo = Object.assign(settleBase,imgInfo)
                this.params ={
                    baseInfo: baseInfo,
                    setterInfo: settleInfo,
                    businessInfo: businessInfo,
                    merType: this.baseInfo.merType
                }
                let result = await MerchantApi.save(this.params)
                if(result.success){
                    this.Message.success('新增成功');
                    this.$router.push({
                        name:'merchantManage'
                    })
                }
            } if(type=='prev'){
                this.active --
            }
        },
    }
}
function filterObj(obj, arr) {
    if (typeof (obj) !== "object" || !Array.isArray(arr)) {
        throw new Error("参数格式不正确")
    }
    const result = {}
    Object.keys(obj).filter((key) => arr.includes(key)).forEach((key) => {
        result[key] = obj[key]
    })
    return result
}
</script>